import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  NativeSelect,
  Typography,
} from '@material-ui/core';
import {getCategories} from 'services/categories';
import {getQuizzes} from 'services/quiz';
import useStyles from './styles';

const Wizard = () => {
  const styles = useStyles();

  const history = useHistory();

  const [categoryId, setCategoryId] = useState(0);
  const [categories, setCategories] = useState([]);
  const [quizId, setQuizId] = useState(0);
  const [quizName, setQuizName] = useState('');
  const [quizzes, setQuizzes] = useState([]);
  const [numQuestions, setNumQuestions] = useState(10);

  useEffect(() => {
    const _getCategories = async () => {
      const {categories} = await getCategories();

      setCategories(categories);
    };

    const _getQuizzes = async () => {
      const {quizzes} = await getQuizzes();

      setQuizzes(quizzes);
    };

    _getCategories();
    _getQuizzes();
  }, []);

  const _handleChangeNumQuestions = ({target}) => {
    const {value} = target;
    setNumQuestions(parseInt(value));
  };

  const _handleChangeCategoryId = ({target}) => {
    const {value} = target;
    const _categoryId = parseInt(value);
    setCategoryId(_categoryId);
  };

  const _handleChangeQuizId = ({target}) => {
    const {value} = target;
    const _quizId = parseInt(value);
    const {name} = quizzes.find(({id}) => id === _quizId);
    setQuizId(_quizId);
    setQuizName(name);
  };

  const _handleClose = () => {};

  const _handleSubmit = () => {
    history.push(
      `/quiz/${quizId}/?categoryId=${categoryId}&numQuestions=${numQuestions}&quizName=${quizName}`
    );
  };

  const isQuizSelected = quizId !== 0;

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      onClose={_handleClose}
      open={true}
    >
      <DialogTitle id="form-dialog-title">Take a Practice Quiz</DialogTitle>
      <DialogContent>
        <FormControl className={styles.formControl} error={!isQuizSelected}>
          <InputLabel shrink htmlFor="select-quiz">
            Level
          </InputLabel>
          <NativeSelect
            value={quizId}
            onChange={_handleChangeQuizId}
            inputProps={{
              name: 'quiz',
              id: 'select-quiz',
            }}
          >
            <option value={0}>Choose One</option>
            {quizzes.map(({id, name}) => {
              return (
                <option key={`quiz-${id}`} value={id}>
                  {name}
                </option>
              );
            })}
          </NativeSelect>
          <FormHelperText>Select the level</FormHelperText>
        </FormControl>
        <FormControl className={styles.formControl}>
          <InputLabel shrink htmlFor="select-numQuestions">
            Number of Questions
          </InputLabel>
          <NativeSelect
            value={numQuestions}
            onChange={_handleChangeNumQuestions}
            inputProps={{
              name: 'numQuestions',
              id: 'select-numQuestions',
            }}
          >
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </NativeSelect>
          <FormHelperText>Select the number of questions</FormHelperText>
        </FormControl>
        <FormControl className={styles.formControl}>
          <InputLabel shrink htmlFor="select-category">
            Category
          </InputLabel>
          <NativeSelect
            value={categoryId}
            onChange={_handleChangeCategoryId}
            inputProps={{
              name: 'category',
              id: 'select-category',
            }}
          >
            <option value={0}>Default Mix</option>
            {categories.map(({id, name}) => {
              return (
                <option key={`category-${id}`} value={id}>
                  {name}
                </option>
              );
            })}
          </NativeSelect>
          <FormHelperText>Optional</FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={!isQuizSelected}
          onClick={_handleSubmit}
        >
          Start Quiz!
        </Button>
      </DialogActions>
      <Typography className={styles.note} color="textSecondary" variant="body2">
        Note: There are NO questions in the practice app for the Intermediate
        Latin Reading Comprehension Exam, since the app does not include any
        Passage Questions. However, if your students would like to practice
        grammatical concepts, you may want to direct them to both the
        Intermediate and Advanced Prose Language Questions.
      </Typography>
    </Dialog>
  );
};

export default Wizard;
