import React, {useEffect} from 'react';
import toastr from 'toastr';
import {AdminTable} from 'components/common/AdminTable';
import {
  QuizzesProvider,
  useQuizzesDispatch,
  useQuizzesState,
} from 'contexts/Admin/Quizzes';
import {getQuizzes} from 'services/quiz';
import {addQuiz, deleteQuiz, updateQuiz} from 'services/admin/quizzes';
import EditQuiz from './EditQuiz';

const COLUMNS = [
  {
    field: 'id',
    title: 'ID',
    searchable: false,
  },
  {
    field: 'name',
    title: 'Name',
  },
  {
    align: 'right',
    field: 'date_added',
    title: 'Date Added',
    searchable: false,
    type: 'datetime',
  },
  {
    align: 'right',
    field: 'date_modified',
    title: 'Date Modified',
    searchable: false,
    type: 'datetime',
  },
];

const _validateForm = (modified) => {
  if (!modified.name || !modified.name.length) {
    toastr.error('You must enter a Category Name!');
    return false;
  }
  if (!modified.config || !modified.config.categoryPercents) {
    toastr.error('You must enter Question Category Percents totalling 100');
    return false;
  }
  const percentsSum = modified.config.categoryPercents.reduce(
    (acc, categoryPercent) => {
      try {
        const sum = parseInt(categoryPercent.percent);
        return acc + sum;
      } catch (err) {
        return acc;
      }
    },
    0
  );
  if (percentsSum !== 100) {
    toastr.error('You must enter Question Category Percents totalling 100');
    return false;
  }
  return true;
};

const Quizzes = () => {
  const dispatch = useQuizzesDispatch();

  const {quizzes, isDialogOpen, lastUpdatedAt, modified, selected} =
    useQuizzesState();

  useEffect(() => {
    const _getQuizzes = async () => {
      const {quizzes} = await getQuizzes();
      dispatch({type: 'SET_QUIZZES', quizzes});
    };

    _getQuizzes();
  }, [dispatch, lastUpdatedAt]);

  const _handleClickAdd = () => {
    dispatch({type: 'SET_IS_DIALOG_OPEN', isDialogOpen: true});
    dispatch({type: 'SET_SELECTED', selected: {}});
  };

  const _handleClickDelete = async ({id}) => {
    const {status} = await deleteQuiz(id);

    if (status === 200) {
      toastr.success('Level deleted');
    }
    dispatch({type: 'SET_LAST_UPDATED_AT'});
  };

  const _handleClickEdit = (rowData) => {
    dispatch({type: 'SET_IS_DIALOG_OPEN', isDialogOpen: true});
    dispatch({type: 'SET_SELECTED', selected: rowData});
  };

  const _handleDialogClose = () => {
    dispatch({type: 'SET_IS_DIALOG_OPEN', isDialogOpen: false});
    dispatch({type: 'SET_SELECTED', selected: {}});
  };

  const _handleDialogSave = async () => {
    const isValid = _validateForm(modified);
    if (!isValid) {
      return;
    }

    const isNew = !selected.id;

    const {status} = isNew
      ? await addQuiz(modified)
      : await updateQuiz(modified);

    if (status === 200) {
      toastr.success(`Level ${isNew ? 'added' : 'updated'}`);
    }

    dispatch({type: 'SET_IS_DIALOG_OPEN', isDialogOpen: false});
    dispatch({type: 'SET_SELECTED', selected: {}});
    dispatch({type: 'SET_LAST_UPDATED_AT'});
  };

  return (
    <>
      <AdminTable
        columns={COLUMNS}
        data={quizzes}
        filtering={false}
        onClickAdd={_handleClickAdd}
        onClickDelete={_handleClickDelete}
        onClickEdit={_handleClickEdit}
        title="Manage Levels"
      />
      <EditQuiz
        isOpen={isDialogOpen}
        onClose={_handleDialogClose}
        onSave={_handleDialogSave}
      />
    </>
  );
};

const Wrapper = () => {
  return (
    <QuizzesProvider>
      <Quizzes />
    </QuizzesProvider>
  );
};

export default Wrapper;
