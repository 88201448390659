const initialState = {
  currentQuestionIndex: 0,
  isLoading: true,
  name: '',
  questions: [],
  responseUuid: null,
  results: [],
};

const resultInitialState = {
  isAnswered: false,
  isCorrect: false,
};

const _getUpdatedResults = (state, action) => {
  const {currentQuestionIndex, results} = state;
  const {correctAnswer, yourAnswer, feedback, isCorrect, question} = action;
  results[currentQuestionIndex] = {
    correctAnswer,
    yourAnswer,
    feedback,
    isCorrect,
    question,
  };
  return results;
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'ANSWER_QUESTION':
      return {
        ...state,
        results: _getUpdatedResults(state, action),
      };
    case 'BEGIN_QUIZ':
      return {
        ...state,
        currentQuestionIndex: 0,
        isLoading: false,
        name: action.quizName,
        questions: action.questions,
        responseUuid: action.responseUuid,
        results: Array.from(action.questions, () => resultInitialState),
      };
    case 'NEXT_QUESTION':
      return {
        ...state,
        currentQuestionIndex: state.currentQuestionIndex + 1,
      };
    default:
      return state;
  }
};

export {initialState, reducer};
