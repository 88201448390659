import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme) => {
  return {
    GoogleLogin: {
      paddingTop: '30vh',
      textAlign: 'center',
    },
  };
});
