import {createMuiTheme} from '@material-ui/core/styles';
import {COLORS} from 'constants/STYLES';

const theme = {
  palette: {
    primary: {
      main: COLORS.purple,
    },
    secondary: {
      main: COLORS.gold,
    },
  },
  overrides: {
    MuiFormControl: {
      root: {
        display: 'block',
      },
    },
    MuiMobileStepper: {
      root: {
        flexGrow: 1,
        minWidth: 400,
      },
      dots: {
        margin: 'auto',
      },
    },
    MUIRichTextEditor: {
      root: {
        border: '1px solid gray',
      },
      editorContainer: {
        border: '1px solid gray',
        margin: 0,
        minHeight: 200,
        padding: 5,
      },
      placeHolder: {
        position: 'static',
      },
    },
    '& .MuiFormControl-root': {
      margin: 2,
      minWidth: 120,
      maxWidth: 300,
    },
  },
};

export default createMuiTheme(theme);
