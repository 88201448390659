import cheerio from 'cheerio';
import draftToHtml from 'draftjs-to-html';

export const getHtml = (raw) => {
  const html = draftToHtml(raw);

  const $ = cheerio.load(html);
  $('img').each((i, element) => {
    const height = $(element).css('height');
    const width = $(element).css('width');

    const fixedHeight = `${height}px`;
    const fixedWidth = `${width}px`;

    $(element).css('height', fixedHeight);
    $(element).css('width', fixedWidth);
  });
  return $.html();
};
