import {fetchWrapper} from 'services/restApi';

export const addCategory = ({name}) => {
  return fetchWrapper('POST', '/api/admin/categories/', {name});
};

export const deleteCategory = (id) => {
  return fetchWrapper('DELETE', '/api/admin/categories/', {id});
};

export const getCategories = () => {
  return fetchWrapper('/api/admin/categories');
};

export const updateCategory = (category) => {
  return fetchWrapper('PUT', '/api/admin/categories/', category);
};
