import React, {useRef, useState} from 'react';
import {convertFromHTML, convertToRaw, ContentState} from 'draft-js';
import toastr from 'toastr';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Typography,
} from '@material-ui/core';
import {AttachFile, CloudUpload, Help} from '@material-ui/icons';
import {FlexContainer, GlobalLoading} from 'components/common';
import {importQuestions} from 'services/admin/importer';
import {isString} from 'utils/dataTypes';
import useStyles from './styles';

const INITIAL_TABLE_HTML = `
  <div></div>
`;
// limit records per request to avoid payload too large errors
const RECORDS_PER_REQUEST = 100;

const _convertHtmlToDraftJS = (html) => {
  if (!isString(html)) {
    return html;
  }
  const {contentBlocks, entityMap} = convertFromHTML(html);
  return convertToRaw(
    ContentState.createFromBlockArray(contentBlocks, entityMap)
  );
};

const _getQuestionsFromHtmlStr = async (html) => {
  // using Cheerio package to create a DOM from an HTML string
  const cheerio = await import('cheerio');
  const $ = cheerio.load(html);
  return $('tr')
    .map((i, element) => {
      return {
        level: $(element).find('td:nth-of-type(1)').text(),
        exam_year: $(element).find('td:nth-of-type(2)').text(),
        category: $(element).find('td:nth-of-type(3)').text(),
        question: _convertHtmlToDraftJS(
          $(element).find('td:nth-of-type(4)').html()
        ),
        a: $(element).find('td:nth-of-type(5)').text(),
        b: $(element).find('td:nth-of-type(6)').text(),
        c: $(element).find('td:nth-of-type(7)').text(),
        d: $(element).find('td:nth-of-type(8)').text(),
        answer: $(element).find('td:nth-of-type(9)').text(),
        incorrect_feedback: _convertHtmlToDraftJS(
          $(element).find('td:nth-of-type(10)').html()
        ),
      };
    })
    .get();
};

const Importer = () => {
  const styles = useStyles();

  const fileInputRef = useRef(null);

  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [tableHtml, setTableHtml] = useState(INITIAL_TABLE_HTML);

  const _handleFabClick = () => {
    setIsHelpOpen(true);
  };

  const _handleFabClose = () => {
    setIsHelpOpen(false);
  };

  const _handleFileChange = async ({target}) => {
    setIsLoading(true);
    const [_file] = target.files;
    const reader = new FileReader();
    const xlsx = await import('xlsx');

    reader.onloadend = async ({currentTarget}) => {
      const {result} = currentTarget;

      const data = new Uint8Array(result);

      const workbook = xlsx.read(data, {type: 'array'});

      const html = xlsx.write(workbook, {
        bookType: 'html',
        sheet: 'data',
        type: 'string',
      });

      // get questions from HTML string and remove header row
      const questionsWithHeader = await _getQuestionsFromHtmlStr(html);

      setIsLoading(false);
      setIsSubmitDisabled(false);
      setQuestions(questionsWithHeader.slice(1));
      setTableHtml(html);
    };

    reader.readAsArrayBuffer(_file);
  };

  const _handleUploadClick = (event) => {
    fileInputRef.current.click();
  };

  const _handleSubmitClick = async () => {
    if (!questions.length) {
      return toastr.error('Upload a file before clicking Submit');
    }
    setIsSubmitDisabled(true);
    toastr.success(
      'Import process started. Another notification will appear when complete'
    );

    let results = [];
    for (let i = 0; i < questions.length; i += RECORDS_PER_REQUEST) {
      const batch = questions.slice(i, i + RECORDS_PER_REQUEST);
      const response = await importQuestions(batch);
      results.push(response);
      console.log('response', response);
    }

    const failedBatches = results.filter(({status}) => status !== 200);

    const areAllSuccess = failedBatches.length === 0;

    if (areAllSuccess) {
      toastr.success(
        'Import successful! You will be sent to the Questions page in 5 seconds'
      );
      setTimeout(() => {
        window.location.replace('/admin/questions');
      }, 5 * 1000);
    } else {
      const numFailedRecords = failedBatches.length * RECORDS_PER_REQUEST;
      toastr.warning(
        `${numFailedRecords} records out of ${questions.length} failed`,
        'Import completed with errors'
      );
    }
  };

  return (
    <>
      <FlexContainer>
        <div style={{flexGrow: 1, padding: 20, textAlign: 'right'}}>
          <input
            accept=".xlsx"
            id="file"
            name="file"
            onChange={_handleFileChange}
            ref={fileInputRef}
            style={{display: 'none'}}
            type="file"
          />
          <Button
            color="secondary"
            onClick={_handleUploadClick}
            startIcon={<AttachFile />}
            style={{width: 200}}
            variant="contained"
          >
            Select File
          </Button>
        </div>
        <div style={{flexGrow: 1, padding: 20}}>
          <Button
            color="primary"
            disabled={isSubmitDisabled}
            endIcon={<CloudUpload />}
            onClick={_handleSubmitClick}
            variant="contained"
          >
            Import
          </Button>
        </div>
      </FlexContainer>
      {isSubmitDisabled && (
        <Typography style={{textAlign: 'center'}} variant="subtitle2">
          Select an Excel spreadsheet (.xlsx) of Questions to import
        </Typography>
      )}
      {isLoading ? (
        <GlobalLoading />
      ) : (
        <div
          className={styles.tableContainer}
          dangerouslySetInnerHTML={{__html: tableHtml}}
        ></div>
      )}
      <Fab className={styles.fab} color="secondary" onClick={_handleFabClick}>
        <Help />
      </Fab>
      <Dialog
        onClose={_handleFabClose}
        open={isHelpOpen}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Help - Import Questions</DialogTitle>
        <DialogContent>
          <ul>
            <li>The expected file format is .xlsx</li>
            <li>The columns are expected to be (in order):</li>
            <ol>
              <li>
                Level -{' '}
                <i>
                  The full name of the Question's Level. Should match what is
                  found under "Levels" in this Admin
                </i>
              </li>
              <li>
                Year - <i>The 4-digit year associated with the Question</i>
              </li>
              <li>
                Category -{' '}
                <i>
                  The full name of the Question's Category. Should match what is
                  found under "Categories" in this Admin
                </i>
              </li>
              <li>
                Question -{' '}
                <i>The text of Question prompt. Preserves formatting</i>
              </li>
              <li>
                Answer A - <i>Text of the first answer</i>
              </li>
              <li>
                Answer B - <i>Text of the second answer</i>
              </li>
              <li>
                Answer C - <i>Text of the third answer</i>
              </li>
              <li>
                Answer D - <i>Text of the fourth answer</i>
              </li>
              <li>
                Correct Answer -{' '}
                <i>
                  Letter of the correct answer (A, B, C, D). Must be capitalized
                </i>
              </li>
              <li>
                Incorrect Response -{' '}
                <i>
                  The text shown to the user in the event of an incorrect
                  response. Preserves formatting
                </i>
              </li>
            </ol>
          </ul>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Importer;
