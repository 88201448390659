import {Book, Category, HelpRounded, Publish} from '@material-ui/icons';

const ADMIN_MENU_ITEMS = [
  {
    displayName: 'Levels',
    name: 'quizzes',
    icon: Book,
    linkTo: '/admin/quizzes',
  },
  {
    displayName: 'Categories',
    name: 'questionCategories',
    icon: Category,
    linkTo: '/admin/question-categories',
  },
  {
    displayName: 'Questions',
    name: 'questions',
    icon: HelpRounded,
    linkTo: '/admin/questions',
  },
  {
    displayName: 'Import Questions',
    name: 'importer',
    icon: Publish,
    linkTo: '/admin/importer',
  },
];

export default ADMIN_MENU_ITEMS;
