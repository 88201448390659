export const isAuthenticated = async () => {
  try {
    const {status} = await fetch('/api/security/authenticate/status');
    return status === 200;
  } catch (err) {
    return false;
  }
};

export const logout = () => fetch('/api/security/authenticate/logout');
