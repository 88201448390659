import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  tableContainer: {
    height: '75vh',
    overflowY: 'auto',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));
