const initialState = {
  isDialogOpen: false,
  isLoading: true,
  lastUpdatedAt: new Date(),
  modified: {},
  questions: [],
  selected: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_NEW_ANSWER_ROW':
      return {
        ...state,
        modified: {
          ...state.modified,
          answers: (state.modified.answers || []).concat([
            {
              is_correct: false,
              prompt: '',
            },
          ]),
        },
      };
    case 'DELETE_ANSWER':
      const remainingAnswers = state.modified.answers.filter(({id}) => {
        return id !== action.id;
      });
      return {
        ...state,
        modified: {
          ...state.modified,
          answers: remainingAnswers,
        },
      };
    case 'SET_ANSWER_TEXT':
      const answers = state.modified.answers || [];
      if (action.index === answers.length) {
        answers.push({is_correct: false, prompt: ''});
      }
      const updatedAnswersText = answers.map((answer, i) => {
        if (i === action.index) {
          return {
            ...answer,
            prompt: action.text,
          };
        }
        return answer;
      });
      return {
        ...state,
        modified: {
          ...state.modified,
          answers: updatedAnswersText,
        },
      };
    case 'SET_CORRECT_ANSWER':
      const updatedAnswersCorrect = state.modified.answers.map((answer, i) => {
        if (i === action.index) {
          return {
            ...answer,
            is_correct: true,
          };
        }
        return {
          ...answer,
          is_correct: false,
        };
      });
      return {
        ...state,
        modified: {
          ...state.modified,
          answers: updatedAnswersCorrect,
        },
      };
    case 'SET_IS_DIALOG_OPEN':
      return {
        ...state,
        isDialogOpen: action.isDialogOpen,
      };
    case 'SET_IS_LOADING':
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case 'SET_LAST_UPDATED_AT':
      return {
        ...state,
        lastUpdatedAt: new Date(),
      };
    case 'SET_MODIFIED':
      return {
        ...state,
        modified: action.modified,
      };
    case 'SET_QUESTIONS':
      return {
        ...state,
        questions: action.questions,
      };
    case 'SET_SELECTED':
      return {
        ...state,
        modified: action.selected,
        selected: action.selected,
      };
    default:
      return state;
  }
};

export {initialState, reducer};
