import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  Paper: {
    border: 'none',
    boxShadow: 'none',
    '& .MuiCard-root': {
      border: 'none',
      boxShadow: 'none',
      '& .MuiCardActions-root': {
        '& .MuiButtonBase-root': {
          margin: 'auto',
        },
      },
    },
  },
  QuestionPrompt: {
    padding: 20,
  },
}));
