import React, {useEffect} from 'react';
import toastr from 'toastr';
import draftToHtml from 'draftjs-to-html';
import {GlobalLoading} from 'components/common';
import {AdminTable} from 'components/common/AdminTable';
import {
  QuestionsProvider,
  useQuestionsDispatch,
  useQuestionsState,
} from 'contexts/Admin/Questions';
import {
  addQuestion,
  deleteQuestion,
  getQuestions,
  updateQuestion,
} from 'services/admin/questions';
import EditQuestion from './EditQuestion';

const COLUMNS = [
  {title: 'Level', field: 'quiz.name', searchable: false},
  {
    title: 'Category',
    field: 'question_category.name',
    searchable: false,
  },
  {
    title: 'Prompt',
    field: 'promptString',
  },
  {
    title: 'Year',
    field: 'exam_year',
    type: 'numeric',
    searchable: false,
  },
  {
    align: 'right',
    field: 'date_added',
    title: 'Date Added',
    filtering: false,
    searchable: false,
    type: 'datetime',
  },
  {
    align: 'right',
    field: 'date_modified',
    title: 'Date Modified',
    filtering: false,
    searchable: false,
    type: 'datetime',
  },
];

const _getPromptString = (prompt) => {
  return draftToHtml(prompt).replace(/(<([^>]+)>)/gi, '');
};

const Questions = () => {
  const dispatch = useQuestionsDispatch();
  const {
    isDialogOpen,
    isLoading,
    lastUpdatedAt,
    questions,
    modified,
    selected,
  } = useQuestionsState();

  useEffect(() => {
    const _getQuestions = async () => {
      const {questions} = await getQuestions();

      const questionsFormatted = questions.map((question) => {
        return {
          ...question,
          promptString: _getPromptString(question.prompt),
        };
      });
      dispatch({type: 'SET_QUESTIONS', questions: questionsFormatted});
      dispatch({type: 'SET_IS_LOADING', isLoading: false});
    };

    _getQuestions();
  }, [dispatch, lastUpdatedAt]);

  const _handleClickAdd = () => {
    dispatch({type: 'SET_IS_DIALOG_OPEN', isDialogOpen: true});
    dispatch({type: 'SET_SELECTED', selected: {}});
  };

  const _handleClickDelete = async (_, {id}) => {
    const {status} = await deleteQuestion(id);

    if (status === 200) {
      toastr.success('Question deleted');
    }
    dispatch({type: 'SET_LAST_UPDATED_AT'});
  };

  const _handleClickEdit = (_, rowData) => {
    dispatch({type: 'SET_IS_DIALOG_OPEN', isDialogOpen: true});
    dispatch({type: 'SET_SELECTED', selected: rowData});
  };

  const _handleDialogClose = () => {
    dispatch({type: 'SET_IS_DIALOG_OPEN', isDialogOpen: false});
    dispatch({type: 'SET_SELECTED', selected: {}});
  };

  const _handleDialogSave = async () => {
    const isNew = !selected.id;

    const {status} = isNew
      ? await addQuestion(modified)
      : await updateQuestion(modified);

    if (status === 200) {
      toastr.success(`Question ${isNew ? 'added' : 'updated'}`);
    }

    dispatch({type: 'SET_IS_DIALOG_OPEN', isDialogOpen: false});
    dispatch({type: 'SET_SELECTED', selected: {}});
    dispatch({type: 'SET_LAST_UPDATED_AT'});
  };

  return isLoading ? (
    <GlobalLoading />
  ) : (
    <>
      <AdminTable
        columns={COLUMNS}
        data={questions}
        onClickAdd={_handleClickAdd}
        onClickDelete={_handleClickDelete}
        onClickEdit={_handleClickEdit}
        title="Manage Questions"
      />
      <EditQuestion
        isOpen={isDialogOpen}
        onClose={_handleDialogClose}
        onSave={_handleDialogSave}
      />
    </>
  );
};

const Wrapper = () => {
  return (
    <QuestionsProvider>
      <Questions />
    </QuestionsProvider>
  );
};

export default Wrapper;
