import {makeStyles} from '@material-ui/core/styles';
import {APP_BAR_HEIGHT} from 'constants/STYLES';

export default makeStyles((theme) => ({
  app: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 1,
  },
  drawerHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    minHeight: APP_BAR_HEIGHT,
  },
}));
