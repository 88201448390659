const initialState = {
  categories: [],
  isDialogOpen: false,
  isLoading: true,
  lastUpdatedAt: new Date(),
  modified: {},
  quizzes: [],
  selected: {},
};

export const getInitialCategoryPercentsConfig = (categories) => {
  return categories.map((category) => {
    return {
      question_category_id: category.id,
      percent: 0,
    };
  });
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CATEGORIES':
      return {
        ...state,
        categories: action.categories,
      };
    case 'SET_CATEGORY_PERCENT':
      const categoryPercentsConfig =
        state.modified.config && state.modified.config.categoryPercents
          ? state.modified.config.categoryPercents
          : getInitialCategoryPercentsConfig(state.categories);
      const updated = categoryPercentsConfig.map((categoryPercent) => {
        if (categoryPercent.question_category_id !== action.id) {
          return categoryPercent;
        }
        return {
          question_category_id: action.id,
          percent: action.percent,
        };
      });
      return {
        ...state,
        modified: {
          ...state.modified,
          config: {
            ...state.modified.config,
            categoryPercents: updated,
          },
        },
      };
    case 'SET_IS_DIALOG_OPEN':
      return {
        ...state,
        isDialogOpen: action.isDialogOpen,
      };
    case 'SET_IS_LOADING':
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case 'SET_LAST_UPDATED_AT':
      return {
        ...state,
        lastUpdatedAt: new Date(),
      };
    case 'SET_MODIFIED':
      return {
        ...state,
        modified: action.modified,
      };
    case 'SET_QUIZZES':
      return {
        ...state,
        quizzes: action.quizzes,
      };
    case 'SET_SELECTED':
      return {
        ...state,
        modified: action.selected,
        selected: action.selected,
      };
    default:
      return state;
  }
};

export {initialState, reducer};
