import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  note: {
    fontSize: '.75rem',
    padding: '8px 24px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
