import React, {useState} from 'react';
import toastr from 'toastr';
import {
  Button,
  Card,
  CardActions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import {useQuizDispatch, useQuizState} from 'contexts/Quiz';
import {answerQuestion} from 'services/quiz';
import {getHtml} from 'utils/draftjs';
import useStyles from './styles';

const DIALOG_TITLE_CORRECT = 'Optimē!';
const DIALOG_TITLE_INCORRECT = 'Ēheu!';
const DIALOG_TITLE_INCORRECT_WITH_FEEDBACK = 'Ēheu! Responsum sit:';

const _hasFeedback = (feedback) => {
  return feedback && feedback.blocks && feedback.blocks.length > 0;
};

const Question = ({id, prompt, question_category, answers}) => {
  const styles = useStyles();

  const dispatch = useQuizDispatch();

  const {responseUuid} = useQuizState();

  const [answerId, setAnswerId] = useState(0);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');

  const _handleChangeAnswer = ({target}) => {
    setAnswerId(parseInt(target.value));
  };

  const _handleClickNextQuestion = () => {
    setDialogTitle('');
    setDialogContent('');
    dispatch({type: 'NEXT_QUESTION'});
  };

  const _handleSubmit = async () => {
    if (answerId === 0) {
      return toastr.error('Please select an answer first!');
    }

    const {correctAnswer, yourAnswer, feedback, isCorrect} =
      await answerQuestion({
        questionId: id,
        answerId,
        responseUuid,
      });

    dispatch({
      type: 'ANSWER_QUESTION',
      correctAnswer,
      yourAnswer,
      feedback,
      isCorrect,
      question: {category: question_category.name, prompt},
    });

    if (isCorrect) {
      setDialogTitle(DIALOG_TITLE_CORRECT);
    } else {
      if (_hasFeedback(feedback)) {
        setDialogTitle(DIALOG_TITLE_INCORRECT_WITH_FEEDBACK);
        setDialogContent(feedback);
      } else {
        setDialogTitle(DIALOG_TITLE_INCORRECT);
        setDialogContent('');
      }
    }
  };

  const promptHtml = getHtml(prompt);

  return (
    <>
      <Card>
        <div className={styles.QuestionPrompt}>
          <div dangerouslySetInnerHTML={{__html: promptHtml}}></div>
        </div>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="answers"
            name="answers"
            onChange={_handleChangeAnswer}
            value={answerId}
          >
            {answers.map(({id, prompt}, i) => {
              return (
                <FormControlLabel
                  control={<Radio />}
                  key={i}
                  label={prompt}
                  value={id}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
        <CardActions>
          <Button color="secondary" onClick={_handleSubmit} variant="contained">
            Respondeō
          </Button>
        </CardActions>
      </Card>
      <Dialog open={!!dialogTitle.length} onClose={_handleClickNextQuestion}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          {_hasFeedback(dialogContent) && (
            <div
              dangerouslySetInnerHTML={{__html: getHtml(dialogContent)}}
            ></div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={_handleClickNextQuestion}
            variant="contained"
          >
            Prōcēde
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Question;
