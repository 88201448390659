import qs from 'query-string';
import {fetchWrapper} from './restApi';

export const answerQuestion = (answer) => {
  return fetchWrapper('POST', '/api/quiz/answerQuestion', answer);
};

export const getQuestions = (quizId, options = {}) => {
  const queryString = qs.stringify(options);

  return fetchWrapper(`/api/quiz/${quizId}/questions?${queryString}`);
};

export const getQuiz = (quizId) => {
  return fetchWrapper(`/api/quiz/${quizId}`);
};

export const getQuizzes = () => {
  return fetchWrapper('/api/quizzes');
};
