import React, {useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

const AlertDialog = ({children, maxWidth = 'md', title = ''}) => {
  const [isOpen, setIsOpen] = useState(true);

  const _handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      onBackdropClick={_handleClose}
      onEscapeKeyDown={_handleClose}
      open={isOpen}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={_handleClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
