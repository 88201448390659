import React from 'react';
import {useLocation} from 'react-router-dom';
import toastr from 'toastr';
import GoogleButton from 'react-google-button';
import {CenteredContent} from 'components/common';
import isLocal from 'utils/isLocal';
import useStyles from './styles';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Login = () => {
  const styles = useStyles();

  const queryParams = useQuery();
  const isLoginFailed = !!queryParams.get('loginFailed');

  const _handleLoginClick = () => {
    const baseUrl = isLocal() ? 'http://localhost:8000' : '';
    window.location = `${baseUrl}/api/security/authenticate`;
  };

  if (isLoginFailed) {
    toastr.error('Unauthorized');
  }

  return (
    <CenteredContent>
      <div className={styles.GoogleLogin}>
        <GoogleButton onClick={_handleLoginClick} style={{margin: '0 auto'}} />
      </div>
    </CenteredContent>
  );
};

export default Login;
