import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {useAppState} from 'contexts/App';

const ProtectedRoute = (props) => {
  const {isLoggedIn} = useAppState();

  if (isLoggedIn !== true) {
    return <Redirect to="/login" />;
  }

  return <Route {...props} />;
};

export default ProtectedRoute;
