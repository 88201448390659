import React, {Suspense, lazy, useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import clsx from 'clsx';
import {Admin, AdminAppBar, Login, MenuDrawer, NotFound} from 'components';
import {GlobalLoading, ProtectedRoute} from 'components/common';
import {useAppDispatch, useAppState} from 'contexts/App';
import {useMenuDispatch} from 'contexts/MenuDrawer';
import {useMenuState} from 'contexts/MenuDrawer';
import {isAuthenticated} from 'services/security';
import useStyles from './styles';

const Categories = lazy(() => import('components/Admin/Categories'));
const Importer = lazy(() => import('components/Admin/Importer'));
const Questions = lazy(() => import('components/Admin/Questions'));
const Quizzes = lazy(() => import('components/Admin/Quizzes'));

const AppMain = ({children}) => {
  const classes = useStyles();

  const {isDrawerOpen} = useMenuState();

  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: isDrawerOpen,
      })}
    >
      <div className={classes.drawerHeader} />
      {children}
    </main>
  );
};

const AdminApp = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const {isLoading} = useAppState();

  const menuDispatch = useMenuDispatch();

  useEffect(() => {
    const _authenticate = async () => {
      const isLoggedIn = await isAuthenticated();

      dispatch({type: 'SET_IS_LOGGED_IN', isLoggedIn});
      menuDispatch({type: 'SET_IS_DRAWER_OPEN', isDrawerOpen: isLoggedIn});
      dispatch({type: 'SET_IS_LOADING', isLoading: false});
    };

    _authenticate();
  }, [dispatch, menuDispatch]);

  return isLoading ? (
    <GlobalLoading />
  ) : (
    <div className={classes.app}>
      <Router>
        <AdminAppBar />
        <MenuDrawer />
        <AppMain>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route exact path="/login" component={Login} />
              <ProtectedRoute exact path="/admin" component={Admin} />
              <ProtectedRoute exact path="/admin/quizzes" component={Quizzes} />
              <ProtectedRoute
                exact
                path="/admin/questions"
                component={Questions}
              />
              <ProtectedRoute
                exact
                path="/admin/question-categories"
                component={Categories}
              />
              <ProtectedRoute
                exact
                path="/admin/importer"
                component={Importer}
              />
              {/* This is placed last to catch all routes not defined above */}
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </Suspense>
        </AppMain>
      </Router>
    </div>
  );
};

export default AdminApp;
