import React from 'react';
import {TabPanel} from 'components/common';
import Question from './Question';

const Questions = ({currentIndex, questions}) => {
  return (
    <>
      {questions.map((question, i) => {
        return (
          <TabPanel key={`tab-${i}`} value={currentIndex} index={i}>
            <Question {...question} />
          </TabPanel>
        );
      })}
    </>
  );
};

export default Questions;
