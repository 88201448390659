import React from 'react';
import useStyles from './styles';

const FlexContainer = ({children, ...props}) => {
  const styles = useStyles();

  return (
    <div className={styles.FlexContainer} {...props}>
      {children}
    </div>
  );
};

export default FlexContainer;
