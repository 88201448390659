import React from 'react';
import {Box, LinearProgress, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

const CustomLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 10,
  },
  bar: {
    borderRadius: 10,
  },
}))(LinearProgress);

const TypographyNoWrap = withStyles((theme) => ({
  root: {
    whiteSpace: 'nowrap',
  },
}))(Typography);

const ProgressHeader = ({currentIndex, numQuestions}) => {
  const questionNumber = currentIndex + 1;

  const progressPct = Math.floor((currentIndex / numQuestions) * 100);
  const progressMsgSuffix = ` / ${numQuestions}`;
  const progressMsgPrefix =
    questionNumber > numQuestions ? numQuestions : questionNumber;
  const progressMsg = progressMsgPrefix + progressMsgSuffix;

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <CustomLinearProgress
          color="secondary"
          variant="determinate"
          value={progressPct}
        />
      </Box>
      <Box minWidth={40}>
        <TypographyNoWrap color="textSecondary" variant="body2">
          {progressMsg}
        </TypographyNoWrap>
      </Box>
    </Box>
  );
};

export default ProgressHeader;
