import React from 'react';
import {useMediaQuery} from '@material-ui/core';
import {FlexContainer, SpacerColumn} from 'components/common';
import useStyles from './styles';

const CenteredContent = ({children}) => {
  const styles = useStyles();

  const shouldFill = useMediaQuery('screen and (max-width:800px)');

  const isMedium = useMediaQuery('screen and (max-width:1000px)');

  let className;
  if (shouldFill) {
    className = styles.fill;
  } else {
    if (isMedium) {
      className = styles.most;
    } else {
      className = styles.half;
    }
  }

  return (
    <FlexContainer>
      <SpacerColumn />
      <div className={className}>{children}</div>
      <SpacerColumn />
    </FlexContainer>
  );
};

export default CenteredContent;
