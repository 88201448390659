import React, {useEffect, useRef, useState} from 'react';
import toastr from 'toastr';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  Popover,
  TextField,
} from '@material-ui/core';
import {AttachFile, Backup, Close, Done, Euro} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import MUIRichTextEditor from 'mui-rte';
import {convertToRaw} from 'draft-js';
import {CopyToClipboardTextButton} from 'components/common';
import {uploadImage} from 'services/admin/questions';
import useStyles from './styles';

const CONTROLS = [
  'bold',
  'italic',
  'underline',
  'quote',
  'highlight',
  'special-characters',
  'numberList',
  'bulletList',
  'media',
  'upload-image',
];

const cardPopverStyles = makeStyles({
  root: {
    padding: 10,
    maxWidth: 350,
  },
  textField: {
    width: '100%',
  },
  input: {
    display: 'none',
  },
});

const UploadImagePopover = (props) => {
  const classes = cardPopverStyles();

  const [anchor, setAnchor] = useState(null);
  const [isCancelled, setIsCancelled] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    setAnchor(props.anchor);
    setFile(null);
    setIsCancelled(false);
  }, [props.anchor]);

  return (
    <Popover
      anchorEl={anchor}
      open={anchor !== null}
      onExited={() => {
        props.onSubmit(file, isCancelled);
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={10}>
          <TextField
            className={classes.textField}
            disabled
            value={file ? file.name || '' : ''}
            placeholder="Click icon to attach image"
          />
        </Grid>
        <Grid item xs={2}>
          <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            type="file"
            onChange={({target}) => {
              const [_file] = target.files;
              setFile(_file);
            }}
          />
          <label htmlFor="contained-button-file">
            <IconButton
              color="primary"
              aria-label="upload image"
              component="span"
            >
              <AttachFile />
            </IconButton>
          </label>
        </Grid>
        <Grid item container xs={12} justify="flex-end">
          <Button
            onClick={() => {
              setAnchor(null);
              setIsCancelled(true);
            }}
          >
            <Close />
          </Button>
          <Button
            onClick={() => {
              setAnchor(null);
              setIsCancelled(false);
            }}
          >
            <Done />
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

const SpecialCharactersPopover = ({anchor, onClose}) => {
  const styles = useStyles();

  return (
    <Popover
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      className={styles.SpecialCharactersPopover}
      open={anchor !== null}
      onClose={onClose}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <div className={styles.lowerCase}>
        <CopyToClipboardTextButton onClick={onClose} text="ā" />
        <CopyToClipboardTextButton onClick={onClose} text="ē" />
        <CopyToClipboardTextButton onClick={onClose} text="ī" />
        <CopyToClipboardTextButton onClick={onClose} text="ō" />
        <CopyToClipboardTextButton onClick={onClose} text="ū" />
      </div>
      <div className={styles.upperCase}>
        <CopyToClipboardTextButton onClick={onClose} text="Ā" />
        <CopyToClipboardTextButton onClick={onClose} text="Ē" />
        <CopyToClipboardTextButton onClick={onClose} text="Ī" />
        <CopyToClipboardTextButton onClick={onClose} text="Ō" />
        <CopyToClipboardTextButton onClick={onClose} text="Ū" />
      </div>
    </Popover>
  );
};

const RichTextEditor = ({
  editorLabel = 'Enter formatted text here...',
  initialValue = {blocks: [], entityMap: {}},
  label,
  onChange,
  ...others
}) => {
  const styles = useStyles();

  const editorRef = useRef(null);
  const defaultValue = useRef(initialValue);

  const [uploadImageAnchor, setUploadImageAnchor] = useState(null);
  const [specialCharactersAnchor, setSpecialCharactersAnchor] = useState(null);

  const _handleChange = (editorState) => {
    const raw = convertToRaw(editorState.getCurrentContent());
    onChange(raw);
  };

  const _uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('image', file);

    const {status, url} = await uploadImage(formData);
    if (status !== 200) {
      return toastr.error('Error uploading image');
    }
    toastr.success('Image upload successful');
    return {
      data: {
        src: url,
        url,
        width: 300,
        height: 200,
        alignment: 'left',
        type: 'image',
      },
    };
  };

  const _handleFileUpload = (file) => {
    if (editorRef && editorRef.current) {
      editorRef.current.insertAtomicBlockAsync(
        'IMAGE',
        _uploadImage(file),
        'Uploading now...'
      );
    }
  };

  const _handleSpecialCharactersPopoverClose = () => {
    setSpecialCharactersAnchor(null);
  };

  return (
    <div className={styles.RichTextEditor}>
      <InputLabel htmlFor="rich-text-editor">{label}</InputLabel>
      <SpecialCharactersPopover
        anchor={specialCharactersAnchor}
        onClose={_handleSpecialCharactersPopoverClose}
      />
      <UploadImagePopover
        anchor={uploadImageAnchor}
        onSubmit={(file, isCancelled) => {
          if (!isCancelled && file) {
            _handleFileUpload(file);
          }
          setUploadImageAnchor(null);
        }}
      />
      <MUIRichTextEditor
        controls={CONTROLS}
        customControls={[
          {
            name: 'special-characters',
            icon: <Euro />,
            type: 'callback',
            onClick: (_editorState, _name, anchor) => {
              setSpecialCharactersAnchor(anchor);
            },
          },
          {
            name: 'upload-image',
            icon: <Backup />,
            type: 'callback',
            onClick: (_editorState, _name, anchor) => {
              setUploadImageAnchor(anchor);
            },
          },
        ]}
        defaultValue={JSON.stringify(defaultValue.current)}
        id="rich-text-editor"
        label={editorLabel}
        maxLength={1000}
        onChange={_handleChange}
        ref={editorRef}
        toolbarButtonSize="small"
        {...others}
      />
    </div>
  );
};

RichTextEditor.propTypes = {
  label: PropTypes.string.isRequired,
};

export default RichTextEditor;
