import React, {useEffect, useState} from 'react';
import toastr from 'toastr';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import {Add, Delete} from '@material-ui/icons';
import {EditDialog, FlexContainer, RichTextEditor} from 'components/common';
import {
  useQuestionsDispatch,
  useQuestionsState,
} from 'contexts/Admin/Questions';
import {getQuizzes} from 'services/quiz';
import {getCategories} from 'services/admin/categories';
import useStyles from './styles';

const _validateForm = (modified) => {
  let isValid = true;
  const errors = [];

  try {
    if (!modified.quiz_id) {
      isValid = false;
      errors.push('Quiz is required');
    }

    if (!modified.question_category_id) {
      isValid = false;
      errors.push('Category is required');
    }

    if (
      !modified.prompt ||
      !modified.prompt.blocks ||
      !modified.prompt.blocks.length
    ) {
      isValid = false;
      errors.push('Question Prompt is required');
    }

    if (!modified.exam_year || modified.exam_year.length !== 4) {
      isValid = false;
      errors.push('Year is required and must be 4 digits');
    }

    const hasInvalidAnswers = (modified.answers || []).find((answer) => {
      return !answer.prompt || !answer.prompt.length;
    });
    const hasOneCorrectAnswer =
      (modified.answers || []).filter((answer) => {
        return answer.is_correct;
      }).length === 1;

    if (hasInvalidAnswers) {
      isValid = false;
      errors.push('At least 2 Answers are required');
    }

    if (!hasOneCorrectAnswer) {
      isValid = false;
      errors.push('Only one Answer can be correct');
    }

    return {
      isValid,
      errors,
    };
  } catch (err) {
    console.error(err);
    return {isValid: false, errors: ['Unknown error']};
  }
};

const Answer = ({answer, index}) => {
  const dispatch = useQuestionsDispatch();

  const _handleChangeAnswerText = ({target}) => {
    const {value} = target;
    dispatch({type: 'SET_ANSWER_TEXT', index, text: value});
  };

  const _handleChangeIsCorrect = ({target}) => {
    if (!target.checked) {
      return toastr.error(
        'To change the Correct Answer, simply check the box for the new Correct Answer'
      );
    }
    dispatch({type: 'SET_CORRECT_ANSWER', index});
  };

  const _handleClickDeleteAnswer = () => {
    dispatch({type: 'DELETE_ANSWER', id: answer.id});
  };

  return (
    <FlexContainer>
      <TextField
        fullWidth
        placeholder="Answer Text"
        onChange={_handleChangeAnswerText}
        style={{maxWidth: '70%'}}
        value={answer.prompt || ''}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={!!answer.is_correct}
            onChange={_handleChangeIsCorrect}
            name="isCorrect"
            color="primary"
          />
        }
        label="Is Correct Answer"
        style={{paddingLeft: '1rem', whiteSpace: 'nowrap'}}
      />
      <IconButton onClick={_handleClickDeleteAnswer}>
        <Delete />
      </IconButton>
    </FlexContainer>
  );
};

const EditQuestion = ({isOpen, onClose, onSave}) => {
  const styles = useStyles();

  const dispatch = useQuestionsDispatch();
  const {modified, selected} = useQuestionsState();

  const [categories, setCategories] = useState([]);
  const [quizzes, setQuizzes] = useState([]);

  useEffect(() => {
    const _getCategories = async () => {
      const {categories, status} = await getCategories();
      if (status === 200) {
        setCategories(categories);
      }
    };

    const _getQuizzes = async () => {
      const {quizzes, status} = await getQuizzes();
      if (status === 200) {
        setQuizzes(quizzes);
      }
    };

    _getCategories();
    _getQuizzes();
  }, []);

  const _handleChangeCategory = ({target}) => {
    const {value} = target;
    dispatch({
      type: 'SET_MODIFIED',
      modified: {
        ...modified,
        question_category_id: value,
      },
    });
  };

  const _handleChangeQuiz = ({target}) => {
    const {value} = target;
    dispatch({
      type: 'SET_MODIFIED',
      modified: {
        ...modified,
        quiz_id: value,
      },
    });
  };

  const _handleChangeIncorrectFeedback = (raw) => {
    dispatch({
      type: 'SET_MODIFIED',
      modified: {
        ...modified,
        incorrect_feedback: raw,
      },
    });
  };

  const _handleChangePrompt = (raw) => {
    dispatch({
      type: 'SET_MODIFIED',
      modified: {
        ...modified,
        prompt: raw,
      },
    });
  };

  const _handleChangeYear = ({target}) => {
    const {value} = target;
    dispatch({
      type: 'SET_MODIFIED',
      modified: {
        ...modified,
        exam_year: value,
      },
    });
  };

  const _handleClickAddNewAnswer = () => {
    dispatch({type: 'ADD_NEW_ANSWER_ROW'});
  };

  const {isValid, errors} = _validateForm(modified);

  const answers = modified.answers || [];

  const quizIdValue = modified.quiz_id || '';
  const questionCategoryIdValue = modified.question_category_id || '';
  const examYearValue = modified.exam_year || '';

  return (
    <EditDialog
      objectName="Question"
      onClose={onClose}
      onSave={onSave}
      isOpen={isOpen}
      saveButtonProps={{disabled: !isValid}}
      {...(!selected.id && {action: 'Add'})}
    >
      {errors.map((error, i) => {
        return (
          <div key={i} style={{color: 'red'}}>
            {error}
          </div>
        );
      })}
      <FlexContainer>
        <FormControl error={!quizIdValue} required>
          <InputLabel id="quiz-label">Level</InputLabel>
          <Select
            labelId="quiz-label"
            id="quiz"
            value={quizIdValue}
            onChange={_handleChangeQuiz}
            style={{minWidth: 160}}
          >
            {quizzes.map(({id, name}) => {
              return (
                <MenuItem key={`quiz-${id}`} value={id}>
                  {name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl
          error={!questionCategoryIdValue}
          required
          style={{marginLeft: '20px', marginRight: '20px'}}
        >
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            labelId="category-label"
            id="category"
            value={questionCategoryIdValue}
            onChange={_handleChangeCategory}
            style={{minWidth: 160}}
          >
            {categories.map(({id, name}) => {
              return (
                <MenuItem key={`category-${id}`} value={id}>
                  {name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TextField
          className={styles.year}
          error={!examYearValue}
          inputProps={{
            maxLength: 4,
          }}
          label="Year"
          onChange={_handleChangeYear}
          required
          value={examYearValue}
          style={{maxWidth: 80}}
        />
      </FlexContainer>
      <RichTextEditor
        {...(selected.prompt && {
          initialValue: selected.prompt,
        })}
        label="Question Prompt"
        onChange={_handleChangePrompt}
      />
      <InputLabel style={{marginTop: 20}}>Answers</InputLabel>
      {answers && answers.length > 0 && (
        <div style={{border: '1px solid gray', padding: 5}}>
          {answers.map((answer, i) => {
            return <Answer answer={answer} index={i} key={`answer-${i}`} />;
          })}
        </div>
      )}

      <Button
        color="primary"
        onClick={_handleClickAddNewAnswer}
        startIcon={<Add />}
      >
        Add New Answer
      </Button>
      <RichTextEditor
        {...(selected.incorrect_feedback && {
          initialValue: selected.incorrect_feedback,
        })}
        label="User Feedback for Incorrect Responses"
        onChange={_handleChangeIncorrectFeedback}
      />
    </EditDialog>
  );
};

export default EditQuestion;
