import React, {useEffect, useState} from 'react';
import toastr from 'toastr';
import {TextField} from '@material-ui/core';
import {EditDialog} from 'components/common';
import {AdminTable} from 'components/common/AdminTable';
import {
  addCategory,
  deleteCategory,
  getCategories,
  updateCategory,
} from 'services/admin/categories';

const COLUMNS = [
  {
    field: 'id',
    type: 'numeric',
    title: 'ID',
  },
  {
    field: 'name',
    title: 'Name',
  },
  {
    align: 'right',
    field: 'date_added',
    title: 'Date Added',
    searchable: false,
    type: 'datetime',
  },
  {
    align: 'right',
    field: 'date_modified',
    title: 'Date Modified',
    searchable: false,
    type: 'datetime',
  },
];

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selected, setSelected] = useState({});
  const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date());

  useEffect(() => {
    const _getCategories = async () => {
      const {categories} = await getCategories();
      setCategories(categories);
    };
    _getCategories();
  }, [lastUpdatedAt]);

  const _handleClickAdd = () => {
    setIsDialogOpen(true);
    setSelected({});
  };

  const _handleClickDelete = async ({id}) => {
    const {status} = await deleteCategory(id);

    if (status === 200) {
      toastr.success('Category deleted');
    }
    setLastUpdatedAt(new Date());
  };

  const _handleClickEdit = (rowData) => {
    setIsDialogOpen(true);
    setSelected(rowData);
  };

  const _handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelected({});
  };

  const _handleDialogSave = async () => {
    const isNew = !selected.id;

    const {status} = isNew
      ? await addCategory(selected)
      : await updateCategory(selected);

    if (status === 200) {
      toastr.success(`Category ${isNew ? 'added' : 'updated'}`);
    }

    setIsDialogOpen(false);
    setSelected({});
    setLastUpdatedAt(new Date());
  };

  const _handleChangeQuizName = ({target}) => {
    const {value} = target;
    setSelected((selected) => ({...selected, name: value}));
  };

  return (
    <>
      <AdminTable
        columns={COLUMNS}
        data={categories}
        filtering={false}
        onClickAdd={_handleClickAdd}
        onClickDelete={_handleClickDelete}
        onClickEdit={_handleClickEdit}
        title="Manage Categories"
      />
      <EditDialog
        objectName="Category"
        onClose={_handleDialogClose}
        onSave={_handleDialogSave}
        isOpen={isDialogOpen}
        {...(!selected.id && {action: 'Add'})}
      >
        <TextField
          label="Name"
          onChange={_handleChangeQuizName}
          value={selected.name || ''}
        />
      </EditDialog>
    </>
  );
};

export default Categories;
