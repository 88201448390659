import React from 'react';
import {Link} from 'react-router-dom';
import {AppBar as MAppBar, Toolbar} from '@material-ui/core';
import useStyles from './styles';
import {APP_NAME} from '../../constants';

const AppBar = ({children}) => {
  const styles = useStyles();

  return (
    <MAppBar className={styles.AppBar} position="fixed">
      <Toolbar variant="dense">
        <div className="AppBar-left">
          <Link to="/">{APP_NAME}</Link>
        </div>
        <div className="AppBar-center">{children}</div>
        <div className="AppBar-right"></div>
      </Toolbar>
    </MAppBar>
  );
};

export default AppBar;
