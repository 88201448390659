import React, {useState} from 'react';
import draftToHtml from 'draftjs-to-html';
import {useHistory} from 'react-router-dom';
import {
  Button,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  CheckCircle,
  KeyboardArrowDown,
  KeyboardArrowUp,
  RemoveCircle,
} from '@material-ui/icons';
import {green, red} from '@material-ui/core/colors';
import {useQuizState} from 'contexts/Quiz';
import {getHtml} from 'utils/draftjs';

const CorrectIcon = () => <CheckCircle style={{color: green[500]}} />;
const IncorrectIcon = () => <RemoveCircle style={{color: red[500]}} />;

const Row = ({questionNumber, result}) => {
  const [isOpen, setIsOpen] = useState(false);

  const Icon = result.isCorrect ? CorrectIcon : IncorrectIcon;

  const questionHtml = getHtml(result.question.prompt);

  return (
    <>
      <TableRow>
        <TableCell>{questionNumber}</TableCell>
        <TableCell>{result.question.category}</TableCell>
        <TableCell>
          <Icon />
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={3}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Typography variant="overline">Question</Typography>
            <div dangerouslySetInnerHTML={{__html: questionHtml}}></div>
            <Typography variant="overline">Your Answer</Typography>
            <Typography>{result.yourAnswer}</Typography>
            <Typography variant="overline">Correct Answer</Typography>
            <Typography>{result.correctAnswer}</Typography>
            {result.feedback &&
            result.feedback.blocks &&
            result.feedback.blocks.length ? (
              <>
                <Typography variant="overline">Tip</Typography>
                <div
                  dangerouslySetInnerHTML={{
                    __html: draftToHtml(result.feedback),
                  }}
                ></div>
              </>
            ) : (
              <></>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const Results = () => {
  const history = useHistory();

  const {name, results} = useQuizState();
  const numCorrect = results.reduce((acc, {isCorrect}) => {
    if (isCorrect) {
      acc++;
    }
    return acc;
  }, 0);
  const numTotal = results.length;

  const percentCorrect = Math.round((numCorrect / numTotal) * 100);

  const _handleClose = () => {
    history.push('/');
  };

  return (
    <>
      <Typography style={{padding: 10}} variant="h5">
        {name}
      </Typography>
      <Typography style={{padding: 10}} variant="subtitle1">
        Quiz complete! You got {numCorrect} of {numTotal} ({percentCorrect}%)
        correct!
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Correct</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((result, i) => {
              const questionNumber = i + 1;
              return (
                <Row key={i} questionNumber={questionNumber} result={result} />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{paddingTop: 20, textAlign: 'center'}}>
        <Button color="secondary" onClick={_handleClose} variant="contained">
          Take Another Quiz
        </Button>
      </div>
    </>
  );
};

export default Results;
