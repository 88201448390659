import toastr from 'toastr';

/**
 * @function fetchWrapper
 * @param {string} arg1 - REST method | url
 * @param {string} [url] - url
 * @param {Object} [body] - body of message
 * @description
 *   Wrapper for the fetch api that provides options defaults and base response code handling.
 * @return {Promise<Object>} A promise containing the deserialized response object.
 * */
export const fetchWrapper = async (arg1, url, body) => {
  // if called with one argument, default to 'GET' method
  const _method = url ? arg1.toUpperCase() : 'GET';
  const _url = url || arg1;

  const options = {
    body: body && JSON.stringify(body), // body can be undefined, that's ok
    headers: {
      'Content-Type': 'application/json',
    },
    method: _method,
  };

  const response = await fetch(_url, options);
  return await _handleResponse(response);
};

/**
 * @function handleResponse
 * @param {Object} response - The response object.
 * @description
 *   A handler for the fetch response Object
 * @return {Promise<Object>} A promise containing the deserialized response object.
 * */
const _handleResponse = async (response) => {
  const {status} = response;
  if (status === 200) {
    return await response.json();
  }

  try {
    return await response.json();
  } catch (err) {
    console.error(err);
    if (status === 400) {
      toastr.error('Bad request');
      throw new Error('Bad request');
    }
    if (status === 401) {
      toastr.error('Unauthorized');
      throw new Error('Unauthorized');
    }
    if (status === 403) {
      toastr.error('Forbidden');
      throw new Error('Forbidden');
    }
    if (status === 404) {
      toastr.error('Not Found');
      throw new Error('Not Found');
    }
    throw new Error('Unknown error');
  }
};
