import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme) => {
  return {
    RichTextEditor: {
      '& .MuiFormLabel-root': {
        marginBottom: 3,
        marginTop: 20,
      },
    },
    SpecialCharactersPopover: {
      '& .MuiButtonBase-root': {
        minWidth: 0,
        width: 30,
      },
      '& .MuiButton-label': {
        textTransform: 'none',
      },
      '& .MuiPopover-paper': {
        maxWidth: 200,
      },
    },
  };
});
