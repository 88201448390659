import React, {useEffect, useState} from 'react';
import toastr from 'toastr';
import qs from 'query-string';
import {Redirect, useLocation} from 'react-router-dom';
import {Paper} from '@material-ui/core';
import {
  AlertDialog,
  CenteredContent,
  GlobalLoading,
  GlobalMessage,
} from 'components/common';
import {QuizProvider, useQuizDispatch, useQuizState} from 'contexts/Quiz';
import {getQuestions} from 'services/quiz';
import ProgressHeader from './ProgressHeader';
import Questions from './Questions';
import Results from './Results';
import useStyles from './styles';

const Quiz = ({categoryId, id, numQuestions, quizName}) => {
  const styles = useStyles();

  const dispatch = useQuizDispatch();
  const {currentQuestionIndex, isLoading, questions} = useQuizState();

  const [hasError, setHasError] = useState(false);
  const [doRedirect, setDoRedirect] = useState(false);

  useEffect(() => {
    const _arePropsValid = () => {
      try {
        if (!Number.isInteger(parseInt(categoryId))) {
          return false;
        }

        if (!Number.isInteger(parseInt(id))) {
          return false;
        }

        if (!Number.isInteger(parseInt(numQuestions))) {
          return false;
        }

        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    };

    const _getQuestions = async () => {
      if (!_arePropsValid()) {
        return setDoRedirect(true);
      }

      const {status, message, questions, responseUuid} = await getQuestions(
        id,
        {
          categoryId,
          limit: numQuestions,
        }
      );

      if (status === 200) {
        dispatch({type: 'BEGIN_QUIZ', questions, quizName, responseUuid});
      } else {
        toastr.error(message);
        setHasError(true);
      }
    };

    _getQuestions();
  }, [categoryId, dispatch, id, numQuestions, quizName]);

  const isCompleted = currentQuestionIndex >= questions.length;
  const showAlertDialog =
    !isLoading && currentQuestionIndex === 0 && questions.length < numQuestions;

  if (doRedirect) {
    return <Redirect to="/" />;
  }

  if (hasError) {
    return <GlobalMessage text="Error retrieving Practice Quiz questions" />;
  }

  return isLoading ? (
    <GlobalLoading />
  ) : (
    <Paper className={styles.Paper}>
      {isCompleted ? (
        <Results />
      ) : (
        <>
          <ProgressHeader
            currentIndex={currentQuestionIndex}
            numQuestions={questions.length}
          />
          <Questions
            currentIndex={currentQuestionIndex}
            questions={questions}
          />
        </>
      )}
      {showAlertDialog && (
        <AlertDialog maxWidth="sm" title="Not enough questions">
          The number of questions retrieved is less than requested
        </AlertDialog>
      )}
    </Paper>
  );
};

const Wrapper = ({match: {params}}) => {
  const {id} = params;
  const {search} = useLocation();
  const queryString = qs.parse(search);

  return (
    <QuizProvider>
      <CenteredContent>
        <Quiz id={id} {...queryString} />
      </CenteredContent>
    </QuizProvider>
  );
};

export default Wrapper;
