import React, {useEffect} from 'react';
import {
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import {EditDialog} from 'components/common';
import {useQuizzesDispatch, useQuizzesState} from 'contexts/Admin/Quizzes';
import {getCategories} from 'services/admin/categories';
import useStyles from './styles';

const EditQuiz = ({isOpen, onClose, onSave}) => {
  const styles = useStyles();

  const dispatch = useQuizzesDispatch();
  const {categories, modified, selected} = useQuizzesState();

  useEffect(() => {
    const _getCategories = async () => {
      const {categories} = await getCategories();
      dispatch({type: 'SET_CATEGORIES', categories});
    };

    _getCategories();
  }, [dispatch]);

  const _handleChangeName = ({target}) => {
    const {value} = target;
    dispatch({
      type: 'SET_MODIFIED',
      modified: {
        ...modified,
        name: value,
      },
    });
  };

  const _handleChangeCategoryWeight = (id, percent) => {
    dispatch({type: 'SET_CATEGORY_PERCENT', id, percent});
  };

  const _getCategoryWeights = () => {
    return categories.map((category) => {
      const categoryPercents =
        modified.config && modified.config.categoryPercents
          ? modified.config.categoryPercents
          : [];

      const categoryConfig = categoryPercents.find((categoryPercent) => {
        return categoryPercent.question_category_id === category.id;
      });

      const percent = categoryConfig ? categoryConfig.percent : 0;

      return {
        ...category,
        percent,
      };
    });
  };

  const _categoryWeights = _getCategoryWeights();

  return (
    <EditDialog
      objectName="Level"
      onClose={onClose}
      onSave={onSave}
      isOpen={isOpen}
      {...(!selected.id && {action: 'Add'})}
    >
      <TextField
        label="Name"
        onChange={_handleChangeName}
        value={modified.name || ''}
      />
      <Typography className={styles.groupLabel}>
        Question Category Weights
      </Typography>
      {_categoryWeights.map((category, i) => {
        const key = `category-${i}-weights`;
        return (
          <FormControl key={i}>
            <Input
              aria-describedby={`${key}-helper-text`}
              inputProps={{
                'aria-label': category.name,
              }}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              id={key}
              onChange={({target}) => {
                const {value} = target;
                _handleChangeCategoryWeight(category.id, parseInt(value));
              }}
              value={category.percent}
            />
            <FormHelperText id={`${key}-helper-text`}>
              {category.name}
            </FormHelperText>
          </FormControl>
        );
      })}
    </EditDialog>
  );
};

export default EditQuiz;
