import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {AppBar, Login, NotFound, Quiz, Wizard} from 'components';
import useStyles from './styles';

const App = () => {
  const classes = useStyles();

  return (
    <div className={classes.app}>
      <Router>
        <AppBar />
        <main className={classes.content}>
          <div className={classes.drawerHeader} />
          <Switch>
            <Route exact path="/" component={Wizard} />
            <Route exact path="/login" component={Login} />
            <Route path="/quiz/:id/" component={Quiz} />
            {/* This is placed last to catch all routes not defined above */}
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </main>
      </Router>
    </div>
  );
};

export default App;
