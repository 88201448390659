import {useMemo} from 'react';
import MaterialTable from 'material-table';
import {tableIcons} from './MTable';

export function AdminTable({
  columns,
  data,
  filtering = true,
  onClickAdd,
  onClickDelete,
  onClickEdit,
  title,
}) {
  const actions = useMemo(() => {
    let _actions = [];

    if (onClickAdd) {
      _actions.push({
        icon: tableIcons.Add,
        tooltip: `Add ${title}`,
        isFreeAction: true,
        onClick: onClickAdd,
      });
    }

    if (onClickEdit) {
      _actions.push({
        icon: tableIcons.Edit,
        tooltip: `Edit ${title}`,
        onClick: onClickEdit,
      });
    }

    if (onClickDelete) {
      _actions.push({
        icon: tableIcons.Delete,
        tooltip: `Delete ${title}`,
        onClick: onClickDelete,
      });
    }

    return _actions;
  }, [onClickAdd, onClickDelete, onClickEdit, title]);

  return (
    <MaterialTable
      actions={actions}
      columns={columns}
      data={data}
      title={title}
      icons={tableIcons}
      options={{
        actionsColumnIndex: -1,
        filtering,
        pageSize: 10,
        pageSizeOptions: [10, 20, 50],
      }}
    />
  );
}
