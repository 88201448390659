import React from 'react';
import ReactDOM from 'react-dom';
import toastr from 'toastr';
import ModeWrapper from 'containers/ModeWrapper';
import * as serviceWorker from './serviceWorker';
import './index.css';
import 'toastr/build/toastr.css';
import {TOASTR_POSITION_CLASS} from 'constants/STYLES';

toastr.options = {
  positionClass: TOASTR_POSITION_CLASS,
};

ReactDOM.render(<ModeWrapper />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
