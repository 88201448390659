import {fetchWrapper} from '../restApi';

export const addQuestion = (question) => {
  return fetchWrapper('POST', '/api/admin/questions/', question);
};

export const deleteQuestion = (id) => {
  return fetchWrapper('DELETE', '/api/admin/questions/', {id});
};

export const getQuestions = () => {
  return fetchWrapper('/api/admin/questions/');
};

export const updateQuestion = (question) => {
  return fetchWrapper('PUT', '/api/admin/questions/', question);
};

export const uploadImage = async (formData) => {
  const response = await fetch('/api/admin/questions/image', {
    method: 'POST',
    body: formData,
  });
  return await response.json();
};
