import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme) => {
  return {
    half: {
      width: '50vw',
    },
    fill: {
      width: '100vw',
    },
    most: {
      width: '75vw',
    },
  };
});
