import {fetchWrapper} from 'services/restApi';

export const addQuiz = (quiz) => {
  return fetchWrapper('POST', '/api/admin/quizzes/', quiz);
};

export const deleteQuiz = (id) => {
  return fetchWrapper('DELETE', '/api/admin/quizzes/', {id});
};

export const updateQuiz = (quiz) => {
  return fetchWrapper('PUT', '/api/admin/quizzes/', quiz);
};
