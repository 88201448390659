import React from 'react';
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Menu} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: 20,
  },
  sub: {
    padding: 20,
  },
}));

const Admin = () => {
  const styles = useStyles();

  return (
    <>
      <Typography className={styles.header} variant="h5">
        Welcome to the Admin Portal for the National Latin Exam Practice Quiz
        app!
      </Typography>
      <Typography className={styles.sub} variant="subtitle1">
        Choose an option from the{' '}
        <Menu color="secondary" style={{verticalAlign: 'middle'}} /> Menu to
        begin
      </Typography>
    </>
  );
};

export default Admin;
