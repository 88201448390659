const isAdmin = () => {
  try {
    const {pathname} = window.location;
    const [firstLevel] = pathname.split('/').filter((_pathname) => {
      return _pathname && _pathname.length;
    });
    return firstLevel.toLowerCase() === 'admin';
  } catch (err) {
    return false;
  }
};

export default isAdmin;
