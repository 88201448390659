import React from 'react';
import Admin from 'containers/Admin';
import App from 'containers/App';
import ProvidersWrapper from 'containers/ProvidersWrapper';
import isAdmin from 'utils/isAdmin';

const ModeWrapper = () => {
  const _isAdmin = isAdmin();

  return <ProvidersWrapper>{_isAdmin ? <Admin /> : <App />}</ProvidersWrapper>;
};

export default ModeWrapper;
