import React, {useRef} from 'react';
import toastr from 'toastr';
import {Button} from '@material-ui/core';
import useStyles from './styles';

const CopyToClipboardTextButton = ({onClick, text}) => {
  const styles = useStyles();

  const textAreaRef = useRef(null);

  const _copyToClipboard = () => {
    textAreaRef.current.select();
    document.execCommand('copy');
    toastr.success('Copied to clipboard');
    onClick();
  };

  return (
    <>
      <Button onClick={_copyToClipboard}>{text}</Button>
      <textarea
        aria-hidden="true"
        className={styles.offscreen}
        readOnly
        ref={textAreaRef}
        value={text}
      />
    </>
  );
};

export default CopyToClipboardTextButton;
